import React from "react"
import ReactSelect from "react-select"

interface IProps {
  options: { label: string; value: string | number }[]
  value: string | number
  onChange: (string) => void
  placeholder?: string
  theme?: {
    selectedColor: string
    focusedColor: string
    borderColor: string
  }
}

const Select = (props: IProps) => {
  const onChange = option => {
    props.onChange(option.value)
  }

  const theme = props.theme || {
    selectedColor: "#EC5E2E",
    focusedColor: "rgb(248, 178, 156, 0.5)",
    borderColor: "rgb(248, 178, 156)",
  }

  const customStyles = {
    option: (provided, state) => {
      let backgroundColor = "#fff"
      if (state.isSelected) backgroundColor = theme.selectedColor
      if (state.isFocused) backgroundColor = theme.focusedColor
      return {
        ...provided,
        color: state.isSelected ? "#fff" : theme.selectedColor,
        backgroundColor,
        padding: 10,
      }
    },
    control: () => ({
      borderColor: "#f00",
      display: "flex",
      borderRadius: "8px",
      backgroundColor: "white",
      boxShadow: "rgb(0 0 0 / 10%) 0px 3px 2px",
      width: "100%",
      outline: "none",
      border: `1.5px solid ${(props.theme || {}).selectedColor}`,
    }),
  }

  return (
    <ReactSelect
      onChange={l => onChange(l)}
      value={props.options.filter(o => o.value === props.value)}
      styles={customStyles}
      isSearchable
      isClearable={false}
      placeholder={props.placeholder}
      options={props.options}
    />
  )
}

Select.defaultProps = {
  placeholder: "",
  theme: {
    selectedColor: "#EC5E2E",
    focusedColor: "rgb(248, 178, 156, 0.5)",
    borderColor: "rgb(248, 178, 156)",
  },
}

export default Select
